import { getAppId } from '@/utils/index';
import { getStorage } from '@/utils/filter';
import request from '../utils/request';
import { getCacheData, setCacheData } from '@/utils/cache';

/**
 * 查询主体对应需要过滤的部门集合
 * @param {number} appId
 * @returns
 */
const filterDeptIds = () => {
  const notVisible = {
    1: [2],
  };
  return (notVisible[getAppId()] || []).join(',');
};

// const appId = getAppId();
/**
 * 查询所有部门
 * @param {number} appId
 * @returns
 */
export const getDepartments = () => {
  return request.get(
    `api/v1.0/app/{app-id}/departments${
      filterDeptIds() ? `?no-need-dept-ids=${filterDeptIds()}` : ''
    }`,
  );
};

/** 查询部门树 */
export const getDepartmentTree = () => {
  return request.get(`/api/v1.0/app/{app-id}/departments/tree`);
};

/**
 * 查询所有部门人员树
 */
export const getDepartmentAndMember = async (refreshScene) => {
  const cacheKey = 'multi-departments_v3';
  const cache = await getCacheData(cacheKey);

  return new Promise((resolve) => {
    if (cache) resolve(JSON.parse(cache.value));
    else
      request
        .get(`/api/v1.0/app/{app-id}/multi-departments`, {
          params: {
            'no-need-dept-ids': filterDeptIds() || '',
            refreshScene,
          },
        })
        .then((res) => {
          try {
            // setSession(cacheKey, JSON.stringify(res));
            setCacheData(cacheKey, JSON.stringify(res));
          } catch (e) {
            console.log(e);
          }
          resolve(res);
        });
  });
};

/**
 * 查询部门人员树（千人千面）
 */
export const getDepartmentAndMembers = async (refreshScene) => {
  return request.get(`/api/v1.0/app/{app-id}/multi-departments-own`, {
    params: {
      'no-need-dept-ids': filterDeptIds() || '',
      refreshScene,
    },
  });
};

/**
 * 查询一位成员的权限
 * @param {number} appId
 * @param {string} userId
 * @returns
 */
export const getMemberRolePermissions = (userId) => {
  return request
    .get(`api/v1.0/app/{app-id}/app-member-rps`, {
      params: {
        userId,
      },
    })
    .then(
      (res) => {
        if (res.success) {
          res.data.resources = res.data.resources || [];
          res.data.resources.unshift({ url: '/app' });
        }
        return res;
      },
      () => {
        // setTimeout(() => {
        //   // window.location.href = '/login';
        // }, 5000);
      },
    );
};

/**
 * 查询部门下所有成员及权限
 * @param {object} data
 * @data
 * @param  {number}   ?pageIndex
 * @param  {number}   ?pageSize
 * @param  {string}   ?busiName       业务名称(姓名或者部门名称)
 * @param  {string}   ?departmentId   部门id
 * @param  {number}   ?fullPartJob    全职兼职: 0 兼职 1 全职
 * @param  {number}   ?hrStatus       hr状态: 0 离职 1 在职
 * @param  {number}   ?roleId         角色id
 * @param  {number}   ?status         激活状态:1=启用 2=已禁用 3=全部
 * @param  {string}   ?orderDirection 排序方向
 * @param  {string}   ?orderBy        排序字段
 * @returns {array}
 */
export const getDeptMemberRolePermissions = (data) => {
  // eslint-disable-next-line
  if (data?.status === undefined) data.status = 3;
  return request.post(`api/v1.0/app/{app-id}/member-role-permissions`, {
    data: {
      needTotalCount: true,
      ...data,
      // status: 1,
    },
  });
  // .then((res) => {
  //   res.data = res.data.map((item) => {
  //     return { ...item, fullPartJob: 1, hrStatus: 1, positionAndDeptName: '咨询师-北京分公司' };
  //   });
  //   return res;
  //   // console.log(res);
  // });
};

/**
 * 更新成员角色权限分配与数据权限分配
 * @param {number} appMemberId
 * @param {object} data
 * @data
 * @param  {string[]} roleIds
 * @param  {number}   scope
 * @returns {array}
 */
export const updateMemberRolePermissions = (appMemberId, data) => {
  return request.put(`api/v1.0/app/{app-id}/app-members/${appMemberId}/member-role-permissions`, {
    data: {
      appId: getAppId(),
      appMemberId,
      ...data,
      status: -1,
    },
  });
};

/**
 * 更新成员状态(启用/禁用)
 * @param {number} appMemberId
 * @param {boolean} enable
 * @returns {array}
 */
export const updateMemberStatus = (appMemberId, enable) => {
  return request.put(`api/v1.0/app/{app-id}/app-members/${appMemberId}/member-role-permissions`, {
    data: {
      appId: getAppId(),
      appMemberId,
      status: enable ? 1 : 0,
    },
  });
};

/**
 * 全量同步部门和用户信息
 * @returns {object:result}
 */
export const syncDepartUser = () => {
  return request.get(`api/v1.0/wx/cp/depart-user`);
};

/** 员工岗位信息列表
 * @param {number} appMemberId
 * @returns {array}
 */
export const getMemberPositionList = (appMemberId) => {
  return request.get(`/api/v1.0/app/{app-id}/app-member/${appMemberId}/app-member-job-data`);
};

/** 查询职务序列
 * @returns {array}
 * 项目id appId?: number | null;
 * 创建时间 createDate?: null | string;
 * 岗位编码 jobCode?: null | string;
 * 职务序列code jobFamilyCode?: null | string;
 * 职务序列名称 jobFamilyName?: null | string;
 * 岗位类型 jobType?: null | string;
 * 岗位名称 name?: null | string;
 * 备注 remark?: null | string;
 * 状态 （0:无效；1:有效） 对应ps状态    A-有效、I-无效  status?: number | null;
 * 修改时间 updateDate?: null | string;
 */
export const getJobSequence = () => {
  return request.get(`/api/v1.0/app/{app-id}/app-family-job-codes`);
};

/** 更新员工信息
 * @param {number} appMemberId    员工id
 * @param {number} appId          主体id
 * @param {string} appMemberName  员工姓名
 * @param {string} psPhone        员工手机号
 * @param {Array}  appMemberJobDataList   岗位列表
 * @param {number} appMemberJobDataList.jobDataId   jobDataId
 * @param {string} appMemberJobDataList.jobName     岗位名称
 * @param {string} appMemberJobDataList.jobCode    岗位编码
 * @param {string} appMemberJobDataList.deptId      部门id
 * @param {string} appMemberJobDataList.deptName    部门名称
 * @param {string} appMemberJobDataList.fullPartTime   主兼职状态 0 兼职 1 主职
 * @param {string} appMemberJobDataList.status         任职状态 0 离职 1 在职
 * @returns {array}
 */
export const updateMemberInfo = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/app-member-job-data`, {
    data,
  });
};

/** 更新员工手机号 */
export const updateMemberPhone = ({ appMemberId, psPhone }) => {
  return request.put(`/api/v1.0/app/{app-id}/outer/app-member/${appMemberId}`, {
    data: {
      id: appMemberId,
      psPhone,
    },
  });
};

/** 新增兼职员工
 * @param {number} appMemberId    员工id
 * @param {number} appId          主体id
 * @param {string} name           员工姓名
 * @param {string} mobile         员工手机号
 * @param {number} gender         员工性别 0未定义 1 男 2 女
 * @param {number} mainDepartment    主部门id
 * @param {string} nationalId        身份证号
 * @param {string} jobCode           岗位编码
 */
export const addPartTimeMember = (data) => {
  const appMemberId = getStorage('appMemberId');
  return request.post(`/api/v1.0/app/{app-id}/app-member/${appMemberId}/part-time-job-member`, {
    data: {
      appPartTimeMemberDTO: { ...data, appId: getAppId() },
    },
  });
};

/** 根据psPhone查询成员列表
 * @param {string} psPhone    员工手机号
 */
export const getMemberListByPsPhone = (psPhone) => {
  return request.get(`/api/v1.0/app/{app-id}/app-members/ps-phone`, {
    params: {
      'ps-phone': psPhone,
    },
  });
};

export default {
  getDepartments,
  getDepartmentAndMember,
  getMemberRolePermissions,
  getDeptMemberRolePermissions,
  updateMemberRolePermissions,
  updateMemberStatus,
  syncDepartUser,
  getMemberPositionList,
  getJobSequence,
};
