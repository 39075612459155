import request from '../utils/request';
import { getAppId } from '@/utils/index';
import { getStorage } from '@/utils/filter';

/**
 * 唯一手机号校验
 * @param {string} phone 手机号
 * @returns {result}
 */
export const onlyCheck = (payload) => {
  return request.get(`api/v1.0/app/${getAppId()}/app-customer-count`, {
    params: {
      ...payload,
    },
  });
};

/**
 * 线索列表
 * @param {string} keyword 姓名/手机号/id
 * @param {string} tagsNames 标签
 * @param {string} intention 意向度
 * @param {string} createDate 创建时间
 * @param {string} all 1:全部，0我的
 * @param {string} pageIndex 页索引
 * @param {string} pageSize 页大小
 * @param {string} orderBy 排序字段
 * @param {string} orderDirection 排序类型
 * @returns {array}
 */
export const getClueList = (payload) => {
  // eslint-disable-next-line no-param-reassign
  if (payload?.signStatus === -1) payload.signStatus = undefined;
  return request.post(`/api/v1.0/app/${getAppId()}/list-app-customer`, {
    data: payload,
  });

  // return request.post(`/api/v1.0/app/${getAppId()}/region-datas/app-customer`, {
  //   data: payload,
  // });
};

/**
 * 新建线索
 * @param {string} name 姓名
 * @param {string} phone 手机号
 * @param {string} address 地址
 * @param {number} allotId 分配的咨询师id
 * @param {number} channelId 一级渠道
 * @param {number} channelId2 二级渠道
 * @param {string} city 市
 * @param {string} cityId 市id
 * @param {string} county 区
 * @param {string} countyId 区id
 * @param {string} creatorMemberName 创建人
 * @param {string} descrition 描述
 * @param {number} grade 年级id
 * @param {string} isClue 线索 1
 * @param {string} province 省
 * @param {string} provinceId 省id
 * @param {number} schoolIntention 辅导意愿
 * @param {string} schoolName 学校名称
 * @param {number} sex 性别
 * @param {number} studentType 学员类型
 * @param {number} telLevel 电话级别二级
 * @param {string} weChatId 微信号
 * @returns {object}
 */
export const addClueInfo = (payload) => {
  if (payload?.referenceOaName) {
    payload.referenceOaName = payload.referenceOaName.trim();
  }
  return request.post(`/api/v1.0/app/${getAppId()}/app-customers`, {
    data: {
      creatorMemberName: getStorage('username'),
      isClue: 1,
      ...payload,
    },
  });
};

/**
 * 查询线索详情
 * @param {number} id 线索id
 * @returns
 */
export const getClueInfo = (id) => {
  return request.get(`/api/v1.0/app/${getAppId()}/region/app-customer/${id}`);
};

/**
 * 编辑线索
 * @param {string} name 姓名
 * @param {string} phone 手机号
 * @param {string} address 地址
 * @param {number} allotId 分配的咨询师id
 * @param {number} channelId 一级渠道
 * @param {number} channelId2 二级渠道
 * @param {string} city 市
 * @param {string} cityId 市id
 * @param {string} county 区
 * @param {string} countyId 区id
 * @param {string} creatorMemberName 创建人
 * @param {string} descrition 描述
 * @param {number} grade 年级id
 * @param {string} isClue 线索 1
 * @param {string} province 省
 * @param {string} provinceId 省id
 * @param {number} schoolIntention 辅导意愿
 * @param {string} schoolName 学校名称
 * @param {number} sex 性别
 * @param {number} studentType 学员类型
 * @param {number} telLevel 电话级别二级
 * @param {string} weChatId 微信号
 * @param {number} id id
 * @param {number} wxStatus 加微情况 1已加 0未加
 * @returns {object}
 */
export const editClueInfo = (payload) => {
  if (payload?.referenceOaName) {
    payload.referenceOaName = payload.referenceOaName.trim();
  }
  return request.put(`/api/v1.0/app/${getAppId()}/app-customer/${payload?.id}`, {
    data: {
      isClue: 1,
      ...payload,
    },
  });
};

/**
 * 获取页面下拉列表  查询唯一元数据 线索 意向度 性别 年级
 * @param {string} name name sex grade clueStage intention telLevel studentType schoolIntention
 * @returns
 */
export const getMetaDatas = (payload) => {
  // /api/v1.0/app/{app-id}/meta-data
  return request.get(`/api/v1.0/app/${getAppId()}/meta-data`, {
    params: {
      region: 'customer',
      name: payload?.name,
    },
  });
};

/**
 * 批量分配归属坐席
 * @param {array} mcs 选中列表 {appMemberId: item?.belongId,appCustomerId: item?.id,}
 * @param {array} targetMemberIds 归属坐席 targetMemberIds: [memberList && memberList[0]?.appMemberId],
 * @returns {object}
 */
export const batchAllocationAgents = (payload) => {
  return request.put(`/api/v1.0/app/${getAppId()}/app-customer-belongs`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 批量分配咨询师
 * @param {array} mcs 选中列表 {appMemberId: item?.allotId,appCustomerId: item?.id,}
 * @param {array} targetMemberIds 咨询师  [memberList[]item?.appMemberId]
 * @returns {object}
 */
export const batchAllocationCounselor = (payload) => {
  return request.put(`/api/v1.0/app/${getAppId()}/app-customer-allots`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 批量分配咨询师 v2.0
 */
export const batchAllocationCounselorV2 = (payload) => {
  return request.put(`/api/v2.0/app/${getAppId()}/app-customer-allots`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 标签下拉
 * @returns {array}
 */
export const getSearchTagList = () => {
  return request.post(`/api/v1.0/app/${getAppId()}/tag`, {
    data: {
      pageIndex: 1,
      pageSize: 50,
    },
  });
};

/**
 * 线索下拉
 * @returns {array}
 */
export const getFirstClueList = async () => {
  return request.get(`/api/v1.0/app/${getAppId()}/channel/${0}`);
};

// export const getSecondClueList = (id) => {
//   return request.get(`/api/v1.0/app/${getAppId()}/channel/${id}`);
// };

/**
 * 获取导出excel模版
 * @param  {string}   ?meteDataRegion    元数据域  此次就是customer
 * @returns {url}
 */
export const downloadExcelTemplate = (meteDataRegion = 'customer') => {
  return request.get(`/api/v1.0/app/{app-id}/template/${meteDataRegion}/download`, {
    responseType: 'blob',
  });
};

/**
 * 获取异步任务分页集合(如批量导入线索、客户)
 * @param  {number}   type       0线索导入 1客户导入 2线索导出  3客户导出 20客户AI分析
 * @returns {records,...}
 */
export const getAsyncTasks = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/heracles-tasks`, {
    data: payload,
  });
};

/**
 * 提交异步任务(如批量导入线索、客户)
 * @param  {string}     description       任务描述
 * @param  {number}     appMemberId       操作人id
 * @param  {number}     type	            任务类型 0线索导入 1客户导入 2线索导出  3客户导出 20客户AI分析
 * @param  {object}     taskExecuteInfo   任务信息
 * @taskExecuteInfo
 * @param  {number}     channelId         渠道id
 * @param  {string}     region            元数据域 此次就是customer
 * @param  {number[]}   allotMemberIds    分配人id集合
 * @param  {string}     fileSourceUrl     导入文件的url
 * @returns {taskInfo}
 */
export const postAsyncTasks = (data) => {
  return request.post(`/api/v1.0/app/{app-id}/heracles-task`, {
    data,
  });
};

/**
 * 跟进提交
 * @param {string} action 'clue_follow'
 * @param {number} appCustomerId 线索id
 * @param {string} remark '跟进信息'
 * @param {string} when 当前跟进时间
 * @returns
 */
export const followBehaviours = (payload) => {
  return request.post(
    `/api/v1.0/app/${getAppId()}/app-customer/${payload?.appCustomerId}/behaviours`,
    {
      data: {
        appId: getAppId(),
        appMemberId: getStorage('appMemberId'),
        ...payload,
      },
    },
  );
};

/**
 * 跟进行为列表
 * 跟进记录
 * @param {string} action 'allot,clue_create,belong,clue_follow,clue_edit,call_record_follow'
 * @param {number} appCustomerId 线索id
 * @param {string} useCase 'clue'
 * @param {number} pageIndex
 * @param {number} pageSize
 * 通话
 * @param {string} action 'communicate_out'
 * @param {number} appCustomerId 线索id
 * @param {number} pageIndex
 * @param {number} pageSize
 * @returns
 */
export const getFollowBehaviours = (payload) => {
  return request.post(`/api/v1.0/app/${getAppId()}/behaviours`, {
    data: {
      // appMemberId:
      appId: getAppId(),
      ...payload,
    },
  });
};
/**
 * 创建上门任务
 * @returns
 */
export const httpCustomerVisit = ({ id, ...rest }) => {
  return request.post(`/api/v1.0/app/1/app-customer/visit/${id}`, {
    data: {
      ...rest,
    },
  });
};

// 根据appId查询所有分公司
export const getCompany = () => {
  return request.get(`/api/v1.0/app/${getAppId()}/app-corp`);
};

// 根据分公司id查询学校
export const getCampus = (deptId) => {
  return request.get(`/api/v1.0/app/${getAppId()}/${deptId}/schools`);
};

// 根据buildCode查询学校
export const getSchool = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/ppts/school/page`, {
    data: {
      pageIndex: 1,
      pageSize: 1000,
      ...payload,
    },
  });
};

/**
 * 新增学员
 * @return
 */
export const addStudent = (payload) => {
  return request.post(
    `/api/v1.0/app/{app-id}/app-customer/${payload.appCustomerId || 0}/app-preparatory-student`,
    {
      data: {
        ...payload,
      },
    },
  );
};

/**
 * 获取学员详情
 * @return
 */

export const getStudentDetail = (id) => {
  return request.get(`/api/v1.0/app/{app-id}/app-student/search/${id}`);
};

/**
 * 编辑学员
 * @return
 */
export const editStudent = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/app-student/${payload.id}`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 获取学员列表
 * @returns
 */
export const getStudentsLists = (payload) => {
  return request.post(
    `/api/v1.0/app/{app-id}/app-students/app-customer-id/${payload.appCustomerId}`,
    {
      data: {
        ...payload,
      },
    },
  );
};

/** 获取最近一次跟进位置
 * @param {object} 同线索查询参数
 */
export const getFollowLocation = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/app-customer/follow-position`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 获取批次列表
 *
 */

export const httpGetBatchLists = (payload) => {
  return request.get(`/api/v1.0/app/{app-id}/heracles-task/batch-list`);
};

/**
 * 查询分公司跟进下拉
 * @returns
 */
export const httpGetFollowTypeOptions = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/resource-master/list-corp-follow-type-options`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 跟进memberid查询分公司id
 * @param {*} payload
 * @returns
 */
export const httpGetAppCampus = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/{appMember-id}/{dept-id}/app-campus`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 根据customer获取分公司id
 * @param {*} payload
 * @returns
 */
export const httpCustomerOwner = (payload) => {
  return request.get(`/api/v1.0/app/{app-id}/find-customer-ownership`, {
    params: {
      ...payload,
    },
  });
};

/**
 * 标记分公司跟进类型是否使用
 * @param {*} payload
 * @returns
 */
export const httpSignCorpFollowType = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/resource-master/sign-used-of-corp-follow-type`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 获取上一次选择的分配咨询师的配置
 */
export const httpMemberExt = () => {
  const appMemberId = getStorage('appMemberId');
  return request.get(`/api/v1.0/app/{app-id}/app-member-ext/${appMemberId}`);
};

/**
 * 修改分配锁定状态
 * @param {Object} payload
 * @param {Number} payload.appMemberId 操作人id
 * @param {Boolean} payload.allotLocked 锁定状态
 * @param {Number} payload.allotCorpId 分公司id
 * @param {Number} payload.allotCampusId 校区id
 * @param {Number} payload.allotId 咨询师id
 * @param {String} payload.allotName 咨询师
 */
export const editAllotLocked = (payload) => {
  return request.put(`/api/v1.0/app/{app-id}/app-member-ext/allot-locked`, {
    data: {
      ...payload,
    },
  });
};
