import { getMetaDatas } from '@/services/customer';
import { getChannelList } from '@/services/system';
import { httpConsultSelect } from '@/utils/tools/dict';
import { getSearchTagList } from '@/services/clue';
import { getAreaTree } from '@/services/region';

// 很多锁
const locks = {};

// type映射
const typeMap = {
  telTransferState: 'telTransferStateOptions', // 转接情况
  schoolIntention: 'schoolIntentionOptions', // 辅导意愿
  studentType: 'studentTypeOptions', // 学员类型
  telLevel: 'telLevelList', // 电话级别
  grade: 'gradeOptions', // 年级
  subject: 'subjectOptions', // 科目
  sex: 'sexOptions', // 性别
  wxStatus: 'addWechatOptions', // 加微情况
  visitor: 'visitorOptions', // 上门人
  intention: 'intentionOptions', // 意向度
  clueStage: 'clueStageOptions', // 线索阶段
};

const enumStore = {
  state: {
    telTransferStateOptions: [], // 转接情况
    schoolIntentionOptions: [], // 辅导意愿
    studentTypeOptions: [], // 学员类型
    telLevelOptions: [], // 电话级别-树形
    telLevelList: [], // 电话级别-平铺
    gradeOptions: [], // 年级
    subjectOptions: [], // 科目
    sexOptions: [], // 性别
    addWechatOptions: [], // 加微情况
    visitorOptions: [], // 上门人
    intentionOptions: [], // 意向度
    clueStageOptions: [], // 线索阶段
    channelOptions: [], // 渠道
    consultSelectOption: [], // 接触方式
    consultSelectAttrOption: [], // 接触方式
    tagOptions: [], // 标签
    provinceOptions: [], // 省市区
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    // 获取枚举值
    async requestMetaData(type, rootState) {
      const dictList = rootState?.enum?.[typeMap[type]];
      if (locks[type] || dictList?.length > 0) return;
      locks[type] = true;
      const res = await getMetaDatas({ name: type });
      if (type === 'telLevel') {
        // 电话级别
        const telLevelType = res?.data?.optionValueV2.filter((f) => f.parentId === 0);
        const treeData = telLevelType.map((m) => {
          return {
            ...m,
            options: res?.data?.optionValueV2.filter((f) => f.parentId === m.id),
          };
        });
        this.updateState({
          telLevelOptions: treeData,
          telLevelList: res?.data?.optionValueV2,
        });
      } else {
        this.updateState({
          [typeMap[type]]: res?.data?.optionValueV2,
        });
      }
      locks[type] = false;
    },
    // 获取渠道
    async requestChannel(_, rootState) {
      const dictList = rootState?.enum?.channelOptions;
      if (locks.channel || dictList?.length > 0) return;
      locks.channel = true;
      const res = await getChannelList();
      const newList = (res?.data || [])?.map((item) => ({
        ...item,
        label: item?.title,
        value: item?.id,
        channelVOs: item?.channelVOs
          ? item?.channelVOs.map((ite) => ({
              ...ite,
              label: ite?.title,
              value: ite?.id,
            }))
          : null,
        children: item?.channelVOs
          ? item?.channelVOs.map((ite) => ({
              ...ite,
              label: ite?.title,
              value: ite?.id,
            }))
          : null,
      }));
      this.updateState({
        channelOptions: newList,
      });
      locks.channel = false;
    },
    // 获取接触方式
    async requestConsultSelect(_, rootState) {
      const dictList = rootState?.enum?.consultSelectOption;
      if (locks.consult || dictList?.length > 0) return;
      locks.consult = true;
      const res = await httpConsultSelect();
      this.updateState({
        consultSelectOption: res?.optionPure,
        consultSelectAttrOption: res?.option,
      });
      locks.consult = false;
    },
    // 获取标签
    async requestTags(_, rootState) {
      const dictList = rootState?.enum?.tagOptions;
      if (locks.tag || dictList?.length > 0) return;
      locks.tag = true;
      const res = await getSearchTagList();
      const newList = (res?.data || [])?.map((item) => ({
        ...item,
        label: item?.title,
        options: item?.tagVOs?.map((ite) => ({ ...ite, label: ite.title, value: ite.id })),
      }));
      this.updateState({
        tagOptions: newList,
      });
      locks.tag = false;
    },
    // 获取省市区
    async requestProvince(_, rootState) {
      const dictList = rootState?.enum?.provinceOptions;
      if (locks.province || dictList?.length > 0) return;
      locks.province = true;
      const res = await getAreaTree(3);
      this.updateState({
        provinceOptions: res?.data,
      });
      locks.province = false;
    },
  },
};

export default enumStore;
