import { httpProductDicts } from '@/utils/tools/dict';

// 很多锁
const locks = {};

const region = {};

const Products = {
  state: {
    courseClassification: [
      {
        value: 1,
        label: '公开课',
      },
      {
        value: 2,
        label: '试听课',
      },
      {
        value: 3,
        label: '正价课',
      },
    ], // 课程性质
    teachingType: [
      {
        value: 1,
        label: '面授',
      },
      {
        value: 2,
        label: '直播',
      },
      {
        value: 3,
        label: '录播',
      },
    ], // 课程方式
    classType: [
      {
        value: 1,
        label: '一对一',
      },
      {
        value: 2,
        label: '小班课',
      },
      {
        value: 3,
        label: '大班课',
      },
    ], // 授课班型
    term: [
      {
        value: 1,
        label: '春季',
      },
      {
        value: 2,
        label: '暑假',
      },
      {
        value: 3,
        label: '秋季',
      },
      {
        value: 4,
        label: '寒假',
      },
    ], // 学期
    stage: [], // 学段
    grade: [], // 年级
    course: [], // 科目
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async getDicts(payload = {}, rootState) {
      const { type } = payload;
      const dicts = rootState?.productCenter?.[type];
      if (locks[type] || dicts?.length > 0) return;
      locks[type] = true;
      const res = await httpProductDicts(type);
      this.updateState({ [type]: res.pickerOptions });
      locks[type] = false;
    },
  },
};

export default Products;
