import axios from 'axios';
import { message } from 'antd';
import { HTTP_STATUS, CODE_CONFIG } from '../consts/statusCode';
import { SCRM_BASEURL } from '../consts/env';
// import { apiErrReport } from './log';
// import Cookie from './tools/cookie';
// import logUtils from './tools/logUtils';
import { getStorage, clearUserStrorage } from './filter';
import webrtc from './webrtc';
import { getUnifiedLoginUrl } from '../services/login';

axios.defaults.withCredentials = false;
const axiosInstanceP = axios.create({
  baseURL: SCRM_BASEURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'app-member-id': Number(getStorage('appMemberId')) || 0,
    'app-id': Number(getStorage('xd_appid')) || 0,
    client: 'PC',
  },
});
// axios.defaults.timeout = 60000;
// axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common['app-member-id'] = Number(getStorage('appMemberId')) || 0;
// axios.defaults.headers.common['app-id'] = Number(getStorage('xd_appid')) || 0;
// axios.defaults.headers.client = 'PC';
axios.defaults.baseURL = SCRM_BASEURL;

const toLogin = async () => {
  // 判断url的query种是否有from参数
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const from = urlParams.get('from');
  if (from === 'sso') {
    const urlRes = await getUnifiedLoginUrl();
    window.location.href = urlRes?.data;
    return;
  }
  window.location.href = '/login';
};

// 中间件 拦截请求-
axiosInstanceP.interceptors.request.use(
  (config) => {
    // console.log();
    const token = getStorage('token');
    if (token) {
      if (!config.disable_token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      // eslint-disable-next-line no-param-reassign
      config.headers['app-member-id'] = Number(getStorage('appMemberId')) || 0;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);
axios.interceptors.response.use(
  (response) => {
    // jwt认证失效
    // if (response?.data?.code === COMMON_STATUS.JWT_FAILED) {
    //   console.log(response);
    //   // window.location.href = '/login';
    // }
    const errCode = response?.data?.errCode;
    if (errCode && CODE_CONFIG[errCode]) {
      message.error(CODE_CONFIG[errCode]);
      return null;
    } else {
      return response;
    }
  },
  (err) => {
    // console.log(err, 'err');
    let errMessage = `服务异常`;
    if (err.message === 'Network Error') {
      errMessage = '网络连接已断开，请检查网络';
    } else if (err.message.indexOf('timeout') > -1) {
      errMessage = '请求超时，请检查网络';
    }

    const res = err.response;

    if (res?.status === HTTP_STATUS.AUTHENTICATE) {
      errMessage = '授权已过期，请重新登录';
      message.destroy();
      message.error(errMessage, 1, async () => {
        webrtc.logout();
        clearUserStrorage();
        // window.location.href = '/login';
        toLogin();
        // const urlRes = await getUnifiedLoginUrl();
        // window.location.href = urlRes;
      });

      return Promise.reject(err);
    }
    message.error(errMessage);
    // apiErrReport(res);
    return Promise.reject(err);
  },
);

const safeRequest = (url, options) => {
  let apiUrl = url;
  if (url.indexOf('{app-id}') > -1) {
    const appId = getStorage('xd_appid');
    if (!appId) {
      toLogin();
      // window.location.href = '/login';
    }
    apiUrl = url.replace('{app-id}', appId);
  }
  return new Promise((resolve, reject) => {
    axiosInstanceP({
      method: 'GET',
      ...options,
      url: apiUrl,
    }).then(
      (res) => {
        if (res) {
          resolve(res.data);
        } else {
          reject(res);
        }
      },
      (err) => {
        reject(err);
      },
    );
  });
};

/**
 * get
 * @param url
 * @param opts
 * @returns {Promise}
 */
const get = (url, opts = {}) => {
  return safeRequest(url, opts);
};

/**
 * post
 * @param url
 * @param opts
 * @returns {Promise}
 */
const post = (url, opts = {}) => {
  return safeRequest(url, {
    ...opts,
    method: 'POST',
  });
};

/**
 * put
 * @param url
 * @param opts
 * @returns {Promise}
 */
const put = (url, opts = {}) => {
  return safeRequest(url, {
    ...opts,
    method: 'PUT',
  });
};

/**
 * del
 * @param url
 * @param opts
 * @returns {Promise}
 */
const del = (url, opts = {}) => {
  return safeRequest(url, {
    ...opts,
    method: 'DELETE',
  });
};

const request = {
  SCRM_BASEURL,
  get,
  post,
  put,
  del,
};

export default request;
