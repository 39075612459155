import request from '../../utils/request';

import { CMS_BASEURL } from '@/consts/env';

/**
 * 获取课程列表
 * @param {Object} payload
 * @param {Number} payload.pageIndex
 * @param {Number} payload.pageSize
 * @param {String} payload.keyword 课程名称或ID
 * @param {Number} payload.courseClassification 课程性质
 * @param {Number} payload.classType 授课班型
 * @param {Number} payload.stageId 学段
 * @param {Number} payload.courseId 科目
 * @param {Number} payload.gradeId 年级
 * @param {Array} payload.categoryProduct 课程分类
 */
export const httpProductList = (payload) => {
  return request.post(`${CMS_BASEURL}/api/v1.0/product/course/page`, {
    data: {
      ...payload,
    },
  });
};

/**
 * 获取课程详情
 * @param {Number} productId 课程id
 */
export const httpProductDetail = (id) => {
  return request.get(`${CMS_BASEURL}/api/v1.0/product/course/get/${id}`);
};

/**
 * 获取表格数据
 * @param payload
 */
export const httpResearchData = (payload) => {
  // console.log(payload, 'payload');

  const { key, ...rest } = payload;
  const path = `${CMS_BASEURL}/api/v1.0/category/teaching/${key}`;
  switch (key) {
    case 'stage':
    case 'term':
    case 'subject':
      return request.get(path);
    case 'grade':
    case 'course':
    case 'version':
    case 'textbook':
      return request.post(path, {
        data: {
          ...rest,
          pageIndex: 1,
          pageSize: 9999,
        },
      });
    default:
      return Promise.resolve({
        data: [],
      });
  }
};
